import React from "react";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

import { useAuthContext } from "../contexts/authContext";
import Header from "../components/Header";
import LoadingStatus from "../components/LoadingStatus";

const ErrorPage: React.FC = () => {
  const { logout } = useAuthContext();

  React.useEffect(() => {
    logout();
    navigate("/login");
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BAM Applied Insights | Logging out...</title>
      </Helmet>
      <div className="flex flex-col min-h-screen scroll-smooth font-sharp-sans">
        <Header showNav={false} />
        <main className="flex flex-col flex-1 w-full overflow-hidden bg-bam-light-blue/50">
          <LoadingStatus loadingType="login_loading" />
        </main>
      </div>
    </>
  );
};

export default ErrorPage;
